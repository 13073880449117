/** @jsx jsx */
import { jsx, Heading } from 'theme-ui';
import * as React from 'react';
import { PageProps } from 'gatsby';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { transparentize } from 'polished';
import { IGatsbyImageData, GatsbyImage } from 'gatsby-plugin-image';
import Layout from '@lekoarts/gatsby-theme-jodie/src/components/layout';
import Seo from '@lekoarts/gatsby-theme-jodie/src/components/seo';
import ReactBeforeSliderComponent from 'react-before-after-slider-component';
import 'react-before-after-slider-component/dist/build.css';

type DataProps = {
  project: {
    body: string;
    excerpt: string;
    color: string;
    date: string;
    slug: string;
    title: string;
    shortTitle: string;
    category: string;
    cover: {
      childImageSharp: {
        resize: {
          src: string;
        };
      };
    };
  };
  images: {
    nodes: {
      name: string;
      childImageSharp: {
        gatsbyImageData: IGatsbyImageData;
      };
    }[];
  };
};

const Project: React.FC<PageProps<DataProps>> = ({
  data: { project, images },
  location,
}) => (
  <Layout color={project.color || undefined}>
    <Seo
      title={project.title}
      description={project.excerpt}
      pathname={location.pathname}
      image={project.cover.childImageSharp.resize.src}
    />
    <div sx={{ variant: `content.project` }}>
      <div
        sx={{
          fontSize: 2,
          textTransform: `uppercase`,
          letterSpacing: `wider`,
          mb: 2,
        }}>
        {project.category}
      </div>
      <Heading as='h1' variant='styles.h1' sx={{ mt: 0 }}>
        {project.title}
      </Heading>
      <div sx={{ maxWidth: `70ch`, my: 4 }}>
        <MDXRenderer>{project.body}</MDXRenderer>
      </div>
    </div>
    <div sx={{ backgroundColor: transparentize(0.9, project.color) }}>
      <div sx={{ variant: `content.imageList` }}>
        {
          //group images by 2
          images.nodes
            .sort((a, b) => {
              //get strings after last '-'
              const aName = a.name.slice(a.name.lastIndexOf('-') + 1);
              const bName = b.name.slice(b.name.lastIndexOf('-') + 1);
              return aName.localeCompare(bName);
            })
            .reduce((resultArray: any, item, index) => {
              const chunkIndex = Math.floor(index / 2);

              if (!resultArray[chunkIndex]) {
                resultArray[chunkIndex] = []; // start a new chunk
              }

              resultArray[chunkIndex].push(item);

              return resultArray;
            }, [])
            .map((imageGroup) => {
              return imageGroup[0].name.includes('after') &&
                imageGroup[1].name.includes('before') ? (
                <div
                  style={{
                    marginBottom: '4rem',
                  }}>
                  <ReactBeforeSliderComponent
                    key={imageGroup[0].name}
                    firstImage={{
                      imageUrl:
                        imageGroup[0]?.childImageSharp.gatsbyImageData.images
                          .fallback.src,
                    }}
                    secondImage={{
                      imageUrl:
                        imageGroup[1]?.childImageSharp.gatsbyImageData.images
                          .fallback.src,
                    }}></ReactBeforeSliderComponent>
                </div>
              ) : (
                <React.Fragment>
                  <GatsbyImage
                    key={imageGroup[0]?.name}
                    alt={imageGroup[0]?.name}
                    image={imageGroup[0]?.childImageSharp.gatsbyImageData}
                  />
                  <GatsbyImage
                    key={imageGroup[1]?.name}
                    alt={imageGroup[1]?.name}
                    image={imageGroup[1]?.childImageSharp.gatsbyImageData}
                  />
                </React.Fragment>
              );
            })
        }
      </div>
    </div>
  </Layout>
);
// images.nodes
//   .sort((a, b) => a.name.localeCompare(b.name))
//   .map((image) => {
//     return (
//       <GatsbyImage
//         key={image.name}
//         alt={image.name}
//         image={image.childImageSharp.gatsbyImageData}
//       />
//     );
//   })}

export default Project;
